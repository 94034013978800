<template>
  <div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="上传图片" prop="iconImage">
        <el-upload
          id="upload"
          name="image"
          :action="ImageUrl"
          list-type="picture-card"
          :on-preview="handlePictureCardPreview"
          :on-success="handleSuccess"
          :limit="1"
          :on-remove="onRemove"
          :file-list="fileList"
          :headers="headers"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible" :modal-append-to-body="false">
          <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
      </el-form-item>
      <el-form-item label="上级分类">
        <el-select
          v-model="ruleForm.parentId"
          style="width: 260px"
          placeholder="请选择上级分类"
        >
          <el-option
            :value="item.id"
            v-for="item in CategoryList"
            :key="item.id"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="分类名称" prop="name">
        <el-input v-model="ruleForm.name" style="width: 260px"></el-input>
      </el-form-item>
      <el-form-item label="权重">
        <el-input v-model="ruleForm.weight" style="width: 260px"></el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="ruleForm.remark" style="width: 260px"></el-input>
      </el-form-item>
      <el-form-item label="价格波动提示">
        <el-radio v-model="ruleForm.prompt" :label= true>显示</el-radio>
        <el-radio v-model="ruleForm.prompt" :label= false>不显示</el-radio>
      </el-form-item>
      <el-form-item>
        <div class="bottom_div">
          <el-button type="primary" @click="submitForm">确定</el-button>
          <el-button @click="resetForm">取消</el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import url from "@/utils/file";
import { getStore } from "@/utils/storage";
import {
  goodsCategoryetaAdd,
  goodsCategoryList,
  goodsCategoryetaEdit,
  goodsCategoryetaDiled,
} from "@/api/goods";
export default {
  props: {
    isType: {
      type: Boolean,
    },
    categoryId: {
      type: String,
    },
  },
  data() {
    return {
      ruleForm: {
        name: "", // 名称
        parentId: "", // 分类
        remark: "", //备注
        iconImage: "", // 图片地址
        id: "", // 主键id
        weight: "", //权重
        prompt: false, // 价格波动
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
          {
            min: 2,
            max: 10,
            message: "长度在 2 到 10 个字符",
            trigger: "blur",
          },
        ],
        iconImage: [
          {
            required: true,
            message: "请选择图片",
            trigger: "change",
          },
        ],
      },
      ImageUrl: url.url_image, //上传地址
      dialogImageUrl: "",
      dialogVisible: false,
      CategoryList: [], // 分类列表
      fileList: [], // 图片回显
    };
  },

  created() {
    goodsCategoryList({
      parentId: "0",
    }).then((res) => {
      this.CategoryList = res.data;
    });
    if (this.isType == false) {
      goodsCategoryetaDiled(this.categoryId).then((res) => {
        this.ruleForm = res.data;
        this.fileList.push({
          url: res.data.iconImage,
        });
      });
    }
  },
  methods: {
    handleSuccess(file, fileList) {
      this.ruleForm.iconImage = file.data; // 保存地址给变量
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 确定
    submitForm() {
      if (this.ruleForm.weight < 0) {
        this.$notify({
          title: "警告",
          message: "权重不能小于0",
          type: "warning",
          duration: 1000,
        });
        return;
      }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.isType) {
            const obj = {
              ...this.ruleForm,
              parentId: "0",
            };
            goodsCategoryetaAdd(
              this.ruleForm.parentId ? this.ruleForm : obj
            ).then((res) => {
              if (res.status == 10000) {
                this.$notify({
                  title: "提示",
                  message: "添加成功",
                  type: "success",
                });
                this.fileList = [];
                this.$emit("isShow");
              }
            });
          } else {
            goodsCategoryetaEdit(this.ruleForm).then((res) => {
              if (res.status == 10000) {
                this.$notify({
                  title: "提示",
                  message: "编辑成功",
                  type: "success",
                });
                this.fileList = [];
                this.$emit("isShow");
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    // 取消
    resetForm() {
      this.clearFn();
      this.$emit("closeFn");
    },
    // 图片移除
    onRemove() {
      this.ruleForm.iconImage = "";
      this.fileList = [];
    },
    // 清空弹框内容
    clearFn() {
      this.ruleForm.name = "";
      this.ruleForm.parentId = null;
      this.ruleForm.remark = "";
      this.ruleForm.enabled = true;
      this.ruleForm.iconImage = "";
      this.dialogImageUrl = "";
      this.fileList = [];
    },
  },
  computed: {
    // 上传组件设置请求头
    headers() {
      return {
        Authorization: getStore("token"), // 直接从本地获取token就行
      };
    },
  },
};
</script>

<style lang='less' scoped>
@import "../../../assets/css/common.less";
.bottom_div {
  width: 250px;
  display: flex;
  justify-content: center;
}
#upload {
  width: 200px;
  // height: 80px;
  /deep/ .el-upload--picture-card {
    width: 60px;
    height: 60px;
    line-height: 65px;
  }

  img {
    width: 60px;
    height: 60px;
  }

  .el-upload-list__item {
    display: inline-block;
    width: 60px;
    height: 60px;
  }
}
</style>
