<template>
  <div class="container">
    <div class="head">
      <div class="dataOper" >
        <div class="OperButton">
          <div class="addBtn" @click="addCategoryeta">
              <img src="../../../assets/images/add.png" style="margin-right: 5px;width: 13px;">添加
          </div>
        </div>
      </div>
    </div>
    <div class="body">
        <el-table
          :data="tableData"
          style="width: 100%;font-family: PingFangRoutine;"
          row-key="id"
          :header-cell-style="tableHeaderColor"
          :tree-props="{ children: 'childList', hasChildren: 'hasChildren' }"
        >
          <el-table-column prop="name"  label="分类名称" width="180">
          </el-table-column>
          <el-table-column label="分类图片" width="180">
            <template slot-scope="scope">
              <div id="image">
                <img :src="scope.row.iconImage" alt="" />
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="添加时间" >
            <template slot-scope="scope">
              <div style="color: #2c2c2c !important;">
                 {{scope.row.createTime}}
              </div>

            </template>
          </el-table-column>
          <!-- <el-table-column prop="goodsNum" label="商品数"> </el-table-column> -->
          <el-table-column prop="weight" label="权重">
             </el-table-column>
          <el-table-column prop="enabled" label="是否显示">
            <template slot-scope="scope">
                <el-switch
                @change='changeComposeStatus(scope.row)'
                    v-model="scope.row.enabled"
                    active-color="rgb(64,158,255)"
                    inactive-color="rgb(138,138,138)">
                  </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="300">
            <template v-slot="scope">
              <div class="operating">
                <div class="edit"  style="margin-right: 5px;" @click="roleEdit(scope.row.id)">
                 <img style="width: 15px;height: 15px;" src="../../../assets/images/edit.png"/>
                </div>
                <div class="delete" style="margin-left: 5px;margin-right: 0px;"  @click="roleDel(scope.row.id)">
                  <i class="el-icon-delete"></i>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
    </div>
    <!-- 弹框 -->
    <el-dialog
      :title="isType ? '添加分类' : '编辑分类'"
      :visible.sync="isShow"
      width="40%"
      :before-close="handleClose"
      :modal-append-to-body="false"
    >
      <Dialog
        :isType="isType"
        @isShow="isShowFn"
        @closeFn="isShow = false"
        :categoryId='categoryId'
        ref="dialog"
        v-if="isShow"
      ></Dialog>
    </el-dialog>
  </div>
</template>
<script>
import {
  goodsCategoryTreeList,
  goodsCategoryetaDelete,
  goodsCategoryetaDiled,
  goodsCategoryChangeStatus
} from "@/api/goods";
import Dialog from "./dialog.vue";
import "../../../font-style/font.css";
export default {
  data() {
    return {
      isShow: false, // 弹框显示隐藏
      tableData: [],
      defaultProps: {
        children: "childList",
        label: "name",
      },
      isType: true, // 弹框标识位
      categoryId: '', //分类id
    };
  },
  components: {
    Dialog,
  },
  activated() {
    this.list();
  },
  methods: {
    tableHeaderColor({ rowIndex }) {
      if (rowIndex === 0) {
        return "background-color: rgba(246, 248, 249, 1);color: #333333;fontSize: '14px';";
      }
    },
    // 获取分类列表树形
    list() {
      goodsCategoryTreeList().then((res) => {
        this.tableData = res.data;
      });
    },
    //编辑
    roleEdit(id) {
      this.isType = false;
      this.isShow = true;
      this.categoryId=id
    },
    //删除
  async  roleDel(id) {
       if (!(await this._box())) return;
     await goodsCategoryetaDelete(id).then((res) => {
        this.list();
      });
    },
    //开关
    changeComposeStatus(val) {
       goodsCategoryChangeStatus({id:val.id}).then((res)=>{
       })
    },
    //添加按钮
    addCategoryeta() {
      this.isType = true;
      this.isShow = true;
    },
    // 点击弹框外触发
    handleClose(done) {
      this.$confirm("确认关闭？").then((_) => {
        done();
      });
    },
    // 确定关闭弹窗
    isShowFn() {
      this.isShow = false;
      this.$refs.dialog.clearFn();
      this.list();
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../assets/css/common.less";

.head{
  margin-bottom: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
}
.text {
  font-size: 14px;
}
.item {
  padding: 18px 0;
}

.box-card {
  width: 100%;
  height: 100%;
}
#image {
  width: 50px;
  height: 50px;
  margin: auto;
  img {
    width: 100%;
    height: 100%;
  }
}
/deep/ .cell {
    text-align: center;
  }
.el-cascader .el-input .el-input__inner {
    text-overflow: ellipsis;
    width: 260px !important;
}
  /deep/ .head .el-card__body{
    padding-left: 0px;
  }
</style>
